/* new home page */

.home--container {
    display: flex;
    flex-direction: column;
    background-color: #000000;
}

.tile-1 {
    position: relative;
}

.slogo--text {
    position: absolute;
    top: 65px;
    left: 30px;
    z-index: 2;
    font-family: 'Roboto';
    color: #ffffff;
    /* Set default text color to white */
    font-size: 70px;
    font-weight: 200;
    /* Semi-transparent background */
}

.slogo--text .red-text {
    color: #e8473e;
    /* Set color of 'people' and 'places' to red */
    font-weight: normal;
}

.slogo--hashtag {
    position: absolute;
    top: 250px;
    left: 30px;
    z-index: 2;
    font-family: 'Roboto';
    color: #e8473e;
    font-size: 60px;
    font-weight: 400;
}

.img1 {
    position: relative;
    /* Change to relative so it respects the flow */
    width: 100vw;
    height: 100vh;
    /* Ensure the image covers the full viewport height */
    background-image: url('../resources/Picture2.jpg');
    /* Replace with your image path */
    background-size: cover;
    /* Ensure the background image covers the whole div */
    background-repeat: no-repeat;
    background-position: center;
    /* Center the image */
}

.img1::before {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.48), rgba(240, 78, 67, 0));
    /* Adjust gradient opacity as needed */
}

.home--hr {
    margin: 0;
    color: red;
    border: 6px solid #e8473e;
}

.home--bottom {
    padding: 20px;
    padding-top: 50px;
    display: flex;
    background-color: black;
    justify-content: center;
    gap: 5%;
}

.home--phone {
    height: 30em;
}

.timer--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    gap: 20px;
}

.timer--container img {
    height: 70px;
}

.timer--table td {
    padding: 20px;
    text-align: center;
}

.timer--head {
    border: none;
    font-size: 20px;
}

.timer--digits {
    font-size: 70px;
    color: #e8473e;
    font-weight: 300;
}

.home--join--button {
    background-color: transparent;
    border: 2px solid grey;
    color: grey;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 28px;
    font-weight: 300;
    margin-top: 20px;
    transition: 0.2s;
}

.home--join--button:hover {
    border: 2px solid white;
    color: white;
}

.home--join--button:active {
    border: 2px solid darkgray;
    color: darkgrey;
}

@media screen and (max-width: 481px) {
    .slogo--text {
        top: 20px;
        left: 10px;
        font-size: 45px;
    }

    .slogo--hashtag {
        top: 190px;
        left: 10px;
        font-size: 45px;
    }

    .home--phone {
        height: 430px;
        width: 320px;
    }

    .home--bottom {
        padding: 20px;
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .timer--table td {
        padding: 5px;
        text-align: center;
    }

    .timer--head {
        border: none;
        font-size: 15px;
    }

    .timer--digits {
        font-size: 50px;
        color: #e8473e;
        font-weight: 300;
    }

    .home--join--button {
        padding: 5px 10px;
        font-size: 24px;
    }
}