.c-main {
    margin: 0;
    background-color: black;
    color: white;
}

.c-hero {
    width: 100vw;
    height: 80vh;
    background: linear-gradient(to bottom, black, transparent),
        url("../../public/contact_hero.png");
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-bottom: 10px solid #e8473e;
}

.c-content {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 50px;
}

.c-content>h1 {
    font-size: 50px;
}

.c-content>p {
    font-size: 20px;
}

.c-red {
    color: #e8473e;
}

.c-emails {
    display: flex;
    justify-content: center;
    gap: 10vw;
}

.c-email-box {
    text-align: center;
    margin-top: 20px;
}

.c-email-box>h2 {
    font-size: 40px;
    margin: 0;
}

.c-email-box>hr {
    color: #e8473e;
    border: 2px solid #e8473e;
    width: 100%;
}

.c-email-box>p {
    margin: 0;
    font-size: 30px;
    font-weight: 300;
}

@media screen and (max-width: 481px) {
    .c-emails {
        gap: 5vw;
    }

    .c-email-box>h2 {
        font-size: 30px;
        margin: 0;
    }

    .c-email-box>hr {
        color: #e8473e;
        border: 2px solid #e8473e;
        width: 80%;
    }

    .c-email-box>p {
        margin: 0;
        font-size: 20px;
        font-weight: 300;
    }
}