.success-container {
    position: relative;
    display: flex;
    background-color: #262626;
    color: white;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    font-family: "Roboto";
    background-image: url('../../public/door_sign.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.success-container-child {
    position: absolute;
    z-index: 1;
    display: flex;
    color: white;
    align-items: center;
    flex-direction: column;
    height: 90vh;
    margin-top: 5vh;
}

.success-container-child h1 {
    font-size: 48px;
    font-weight: 400;
    text-align: center;
}

.success-container-child p {
    font-size: 28px;
    font-weight: lighter;
    margin: 0;
    text-align: center;
}

.success-container-child button {
    width: 200px;
    border: none;
    color: #e8473e;
    background-color: transparent;
    border: 3px solid #e8473e;
    font-family: "Roboto";
    font-size: 24px;
    padding: 10px;
    margin: 10px;
    border-radius: 6px;
    margin-top: 60%;
    font-weight: bold;
    transition: 0.3s;
}

.success-container button:hover {
    color: white;
    border: 3px solid white;
}

.success-container button:active {
    border: 3px solid grey;
}

@media screen and (max-width: 481px) {
    .success-container-child p {
        font-size: 24px;
    }

    .success-container-child button {
        margin-top: 85%;
    }
}
