.coming-soon-container {
  display: flex;
  background-color: #262626;
  color: white;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.coming-soon-container>img {
  margin-top: 50px;
}

/* 404 page */

.page-not-found {
  padding-top: 40px;
  margin: 0;
  height: 100vh;
  background-color: #262626;
  color: #e8473e;
  font-size: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-not-found h1 {
  margin: 0;
  font-family: 'Roboto';
  font-weight: 300;
}

.page-not-found img {
  height: 110px;
}