.main {
    margin: 0;
    background-color: black;
    color: white;
}

.hero {
    width: 100vw;
    height: 80vh;
    background: linear-gradient(to bottom, black, transparent),
        url("../../public/about_hero.png");
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-bottom: 10px solid #e8473e;
}

.main--title {
    top: 100px;
    left: 30px;
    font-weight: 300;
    font-size: 60px;
    color: white;
    position: absolute;
}

.main--red {
    color: #e8473e;
    font-weight: normal;
}

.box-1 {
    margin: 5vh 3vw;
    margin-right: 10vw;
    padding: 10px 30px;
    background-color: #181818;
    border-radius: 20px;
}

.box-1>h2 {
    margin: 0;
    color: #e8473e;
    font-size: 60px;
}

.box-1>p {
    font-size: 28px;
    font-weight: 300;
}

.founder-parent {
    position: relative;
    height: 100px;
}

.founder-box {
    position: absolute;
    display: flex;
    gap: 20px;
    right: -100px;
}

.founder-box>img {
    border: 5px solid black;
    border-radius: 100px;
}

.founder-text {
    display: flex;
    flex-direction: column;
    justify-content: end;
    text-align: right;
}

.founder-text * {
    margin: 0;
}

.box-2 {
    position: relative;
    display: flex;
    justify-content: start;
    margin-right: 5vw;
    margin-top: 30px;
}

.box-2>img {
    padding-left: 5vw;
    padding-right: 30vw;
    border-bottom: 7px solid #e8473e;
}

.box-2-text {
    position: absolute;
    top: 20px;
    width: 60vw;
    right: 1vw;
    max-width: 50vw;
    text-align: right;
}

.box-2-text>h2 {
    font-size: 3vw;
}

.box-2-text>p {
    font-size: 1.8vw;
    font-weight: 300;
}

.box-3 {
    position: relative;
    display: flex;
    justify-content: end;
    margin-top: 30px;
}

.box-3>img {
    padding-left: 30vw;
    padding-right: 5vw;
    border-bottom: 7px solid #e8473e;
}

.box-3-text {
    position: absolute;
    top: 20px;
    width: 45vw;
    left: 5vw;
    text-align: left;
}

.box-3-text>h2 {
    font-size: 3vw;
}

.box-3-text>p {
    font-size: 2vw;
    font-weight: 300;
}

.box-4 {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box-4-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4vw;
}

.box-4>p {
    font-weight: 300;
    font-size: 2vw;
    margin: 20px 100px;
}

.box-4-images {
    position: relative;
    height: 500px;
}

.box-4-images>img {
    height: 500px;
    position: relative;
}

.box-4-img-1 {
    left: 50px;
    z-index: 1;
}

.box-4-img-2 {
    right: 50px;
}

.box-4-dawn>h2,
.box-4-dusk>h2 {
    margin: 0;
    font-size: 3vw;
}

.box-4-dawn>img,
.box-4-dusk>img {
    width: 70px;
}

.box-4-border {
    border: 4px solid #e8473e;
    width: 80%;
}

.about-drop {
    margin-top: 20px;
    height: 80px;
}

@media screen and (max-width: 481px) {
    .main--title {
        top: 20vh;
    }

    .box-1 {
        margin-bottom: 100px;
    }

    .founder-box {
        right: -30px;
    }

    .founder-box>img {
        height: 100px;
    }

    .founder-parent {
        height: 50px;
    }

    .box-2 {
        margin-right: 5vw;
        height: 400px;
    }

    .box-2>img {
        height: 200px;
        padding-left: 2vw;
        padding-right: 20vw;
        padding-top: 120px;
        border-bottom: 4px solid #e8473e;
    }

    .box-2-text {
        position: absolute;
    }

    .box-2-text>h2 {
        font-size: 32px;
        margin: 0;
    }

    .box-2-text>p {
        font-size: 18px;
        font-weight: 300;
    }

    .box-3 {
        margin-right: 5vw;
        height: 400px;
    }

    .box-3>img {
        height: 200px;
        padding-left: 20vw;
        padding-right: 2vw;
        padding-top: 220px;
        border-bottom: 4px solid #e8473e;
    }

    .box-3-text {
        position: absolute;
    }

    .box-3-text>h2 {
        font-size: 32px;
        margin: 0;
    }

    .box-3-text>p {
        font-size: 18px;
        font-weight: 300;
    }

    .box-4 {
        margin-top: 30px;
    }
    
    .box-4-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2vw;
    }
    
    .box-4>p {
        font-weight: 300;
        font-size: 4vw;
        margin: 20px 20px;
    }
    
    .box-4-images {
        position: relative;
        height: 200px;
    }
    
    .box-4-images>img {
        height: 200px;
        position: relative;
    }
    
    .box-4-img-1 {
        left: 30px;
    }
    
    .box-4-img-2 {
        right: 30px;
    }
    
    .box-4-dawn>h2,
    .box-4-dusk>h2 {
        margin: 0;
        font-size: 3vw;
    }
    
    .box-4-dawn>img,
    .box-4-dusk>img {
        width: 50px;
    }
    
    .box-4-border {
        border: 2px solid #e8473e;
        width: 80%;
    }
    
    .about-drop {
        height: 70px;
    }
}