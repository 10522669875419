.header--container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    background-color: #000000;
    justify-content: space-between;
    position: relative;
    /* Ensure the header is positioned above the content */
    z-index: 1;
    /* Set a high z-index to keep the header on top */
}

.header--container p {
    font-weight: 275;
    font-size: 28px;
    margin: 0;
    margin-top: 40px;
}

.header--logo {
    width: 320px;
    height: 100px;
    margin-right: 20px;
}

.header--nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 20px;
    /* Adjust the margin-right to create space */
    padding-right: 20px;
    gap: 40px;
    /* Space between nav items */
}

.header--nav-item {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: color 0.3s;
    color: #FFFFFF;
    text-decoration: none;
}

.header--nav-item:hover {
    color: #F04E43;
    /* Change color on hover */
}

/* Phone screens */
@media screen and (max-width: 481px) {
    .header--container {
        flex-direction: column;
        align-items: center;
        padding: 10px;
        background-color: #000000;
        position: relative;
        /* Ensure the header is positioned above the content */
        z-index: 1;
        /* Set a high z-index to keep the header on top */
    }

    .header--nav {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
    }

    .header--nav p {
        margin-top: 10px;
    }

    .header--logo {
        margin: 0px;
    }
}