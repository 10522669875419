/* waitlist page */
.contact--container {
    display: flex;
    flex-direction: column;
    background-color: #000000;
    height: 100vh;
}

.contact--img {
    margin-top: 120px;
    height: 100px;
}

.form--container {
    background-color: #000000;
    padding: 40px;
}

.form--container h1 {
    color: #ffffff;
    text-align: center;
    font-size: 65px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0px;
    background-image: linear-gradient(90deg, white, #5e5e5e);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

.form--container p {
    color: #7c7c7c;
    text-align: center;
    font-size: 20px;
    margin: 0px 23%;
    margin-top: 10px;
    margin-bottom: 0px;
}

.contact--form {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 50px 20%;
    align-items: center;
}

.form--group {
    display: flex;
    flex-direction: column;
}

.form--input {
    display: flex;
    flex-direction: column;
    width: 75%;
}

.form--button {
    display: flex;
    flex-direction: column;
    width: 35%;
}

label {
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 8px;
}

input[type="text"],
input[type="email"],
input[type="password"] {
    padding: 20px 15px;
    font-size: 20px;
    border: none;
    border-radius: 10px;
    background-color: rgb(36, 36, 36);
    color: white;
}

button[type="submit"] {
    padding: 20px 15px;
    font-size: 20px;
    background-color: transparent;
    color: #e8473e;
    border: 3px solid #e8473e;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button[type="submit"]:hover {
    color: white;
    border: 3px solid white;
}

input[type="email"]:focus {
    outline: none;
    border: 1px solid white;
}

@media screen and (max-width: 481px) {
    .contact--form {
        flex-direction: column;
        margin: 0;
        margin-top: 30px;
    }

    .form--input {
        width: 100%;
    }
    
    .form--button {
        margin-top: 20px;
        width: 100%;
    }
}