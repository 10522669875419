.footer--container {
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 30px 0px;
    background-color: #000000;
    align-items: flex-end;
    position: relative;
    z-index: 1;
}

.footer--nav {
    display: flex;
    align-items: center;
    /* Adjust the margin-right to create space */
    gap: 100px;
    /* Space between nav items */
}

.footer--nav p {
    font-size: 20px;
    font-weight: 300;
}

.footer--nav-item {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: color 0.3s;
    color: #FFFFFF;
    text-decoration: none;
}

.footer--nav-item:hover {
    color: #F04E43;
    /* Change color on hover */
}