body {
    background-color: black;
    height: 100vh;
}

.main {
    color: white;
    text-align: center;
}

.main-form {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin: 0px 200px;
}

.checkbox-box {
    display: flex;
}